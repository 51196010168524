import React, { useEffect, useState } from 'react';
import '../styles/index.css';

function Index() {
  /*
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);
*/
  return (
    <main>
      <h1>Tranoo Projects</h1>
      <p>
        <a href="https://swap.tranoo.com/">
          Tranoo Swap
        </a>
      </p>
    </main>
  );
}

export default Index;
